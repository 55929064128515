
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useEffect } from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { GoogleAnalytics } from "@next/third-parties/google";
// Context
import { UserProvider } from "@/context/user";
// Services
import { clientFetchService, serverFetchService } from "@/services/fetch";
// Utils
import { checkAndClearCookies, getCookie } from "@/utils/cookies";
import "../styles/globals.css";
import { ThemeProvider } from "@/context/theme";
import ErrorBoundary from "@/components/common/error-boundary";
import { CheckoutProvider } from "@/context/checkout";
import { PreviousPathProvider } from "@/context/previouspath";
import { KumuModeProvider } from "@/context/kumu-mode";
import { UploaderProvider } from "@/context/uploader";
import { figtree } from "styles/fonts";
type OwnProps = {
    key: string;
    user: User;
    email: string;
};
type Props = React.PropsWithChildren<OwnProps>;
const KumuApp = ({ Component, pageProps, user, email }: AppProps & Props) => {
    useEffect(() => {
        try {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                for (const registration of registrations) {
                    registration.unregister();
                }
            });
        }
        catch (e) {
            console.log("error removing SW");
        }
    }, []);
    useEffect(() => {
        checkAndClearCookies();
    }, []);
    return (<main className={figtree.className}>
      <Head>
        <title>Kumu</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"/>
      </Head>
      <ErrorBoundary>
        <UserProvider value={{
            user: user,
            email: email,
        }}>
          <ThemeProvider color="green">
            <UploaderProvider>
              <PreviousPathProvider>
                <KumuModeProvider>
                  <CheckoutProvider>
                    <Component {...pageProps}/>
                    <GoogleAnalytics gaId="G-SE9N6M74JG"/>
                  </CheckoutProvider>
                </KumuModeProvider>
              </PreviousPathProvider>
            </UploaderProvider>
          </ThemeProvider>
        </UserProvider>
      </ErrorBoundary>
    </main>);
};
/**
 *
 * This method is tricky, basically it only executes twice.
 * Once on SSR for every page that is requested, this means on every hit to the server for fetching a new page.
 * And also on client side but only for client side routing, it's not like it's going to execute once per page
 * on client side, it's only to do that if you use nextjs's linking
 */
KumuApp.getInitialProps = async ({ ctx }: {
    ctx: {
        req: any;
    };
}) => {
    let cookie;
    if (ctx.req) {
        cookie = ctx.req.headers?.cookie;
    }
    else {
        cookie = document.cookie;
    }
    const accessToken = getCookie(cookie, "accessToken");
    const emailCookie = getCookie(cookie, "email");
    if (!accessToken || accessToken === "") {
        return {
            user: {},
            email: emailCookie,
        };
    }
    let user: User;
    const isClient = typeof window !== "undefined";
    user = isClient
        ? await clientFetchService("/api/users", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        : await serverFetchService("/auth", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    return {
        key: user.id,
        user: user,
        email: emailCookie,
    };
};
const __Next_Translate__Page__1917458fa27__ = KumuApp;

    export default __appWithI18n(__Next_Translate__Page__1917458fa27__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  